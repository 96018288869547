const mouseXY = { x: null, y: null }

document.addEventListener("dragover", function (e) {
	mouseXY.x = e.clientX
	mouseXY.y = e.clientY
})

export default {
	data() {
		return {
			mouseXY
		}
	},

	methods: {
		/**
		 * @param {HTMLElement} el
		 */
		isMouseInside(el) {
			const rect = el.getBoundingClientRect()

			return {
				inside:
					this.mouseXY.x > rect.left &&
					this.mouseXY.x < rect.right &&
					this.mouseXY.y > rect.top &&
					this.mouseXY.y < rect.bottom,
				rect
			}
		}
	}
}
