export default {
	props: {
		value: {},
		item: {},
		options: {}
	},

	computed: {
		local: {
			get() {
				const tr = this.item.transformer

				if (tr && tr.get) {
					return tr.get(this.value)
				}

				return this.value
			},

			set: function (value) {
				const tr = this.item.transformer

				if (tr && tr.set) {
					value = tr.set(value)
				}

				this.$emit("input", value)
			}
		}
	}
}
