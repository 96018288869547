<template>
    <div>
        <span class="mr-1">{{ item.label || item.name }}</span>
        <input class="form-control form-control-sm" type="text" v-model="local" />
    </div>
</template>
<script>
import debounce from "lodash.debounce"

export default {
    name: "BuilderText",
    props: {
        value: {},
        item: {},
        options: {}
    },

    computed: {
        local: {
            get() {
                const tr = this.item.transformer

                if (tr && tr.get) {
                    return tr.get(this.value)
                }

                return this.value
            },

            set: debounce(function (value) {
                const tr = this.item.transformer

                if (tr && tr.set) {
                    value = tr.set(value)
                }

                this.$emit("input", value)
            }, 500)
        }
    }
}
</script>
