<template>
    <div class="m-4">
        <input type="checkbox" class="form-check-input" id="exampleCheck1" v-model="local" />
        <label class="form-check-label" for="exampleCheck1">{{ item.label || item.name }}</label>
    </div>
</template>
<script>
export default {
    name: "BuilderSwitch",
    props: {
        item: {}
    },

    computed: {
        local: {
            get() {
                return this.value
            },

            set(value) {
                this.$emit("input", value)
            }
        }
    }
}
</script>
