<template>
    <div>
        <DashboardBuilder>
            <template v-slot:grid-layout>
                <ResizableLayout v-model.lazy="layout" />
            </template>
            <template v-slot:editor>
                <GenerateLayout :data="layout" :selected="selected" />
            </template>
        </DashboardBuilder>
    </div>
</template>

<script>
import ResizableLayout from "@Platon/components/builder/ResizableLayout"
import DashboardBuilder from "@Platon/components/builder/DashboardBuilder"
import GenerateLayout from "@Platon/components/builder/GenerateLayout"

export default {
    name: "TestResizablePage",
    components: { DashboardBuilder, ResizableLayout, GenerateLayout },

    provide() {
        return {
            dashboard: this
        }
    },

    data() {
        return {
            currentChart: {},
            selected: {},
            layout: [
                {
                    x: 0,
                    y: 0,
                    w: 12,
                    h: 13,
                    i: "0",
                    type: "chart",
                    options: {
                        type: "tree",
                        colors: ["#f00", "#0f0", "#00f", "cyan"],
                        data: [
                            { title: "Manaviy hordiq", val: 10 },
                            { title: "Iqtisodiy erkinlik", val: 30 },
                            { title: "Oziq-ovqat", val: 70 },
                            { title: "Xavfsizlik", val: 100 }
                        ]
                    }
                }
            ]
        }
    },

    methods: {
        selectItem(element) {
            this.selected = element
        },

        changeChartOptions({ options, index }) {
            Object.assign(this.layout[index].options, options)
        }
    }
}
</script>
