<template>
    <div
        @drag="$emit('drag')"
        @dragend="$emit('dragend', item.type)"
        class="droppable-element btn btn-white moveable mr-2"
        draggable="true"
        unselectable="on"
    >
        {{ title }}
    </div>
</template>
<script>
export default {
    props: {
        item: {},
        title: {
            required: true,
            type: String,
            default: "Drag and Drop"
        }
    }
}
</script>
<style lang="scss">
.moveable {
    &:hover {
        cursor: move !important;
    }
}
</style>
