<template>
    <div>
        <BuilderGroup v-model="local" :item="groupOptions" />
    </div>
</template>
<script>
import BuilderGroup from "./BuilderGroup"

export default {
    name: "FormBuilder",

    components: {
        BuilderGroup
    },

    props: {
        form: {
            default: () => {}
        },
        value: {}
    },

    computed: {
        groupOptions() {
            return {
                children: this.form
            }
        },
        local: {
            get() {
                return this.value
            },

            set(value) {
                this.$emit("input", value)
            }
        }
    }
}
</script>
<style lang=""></style>
