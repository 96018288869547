<template>
    <splitpanes class="default-theme" :push-other-panes="false">
        <pane>
            <splitpanes :push-other-panes="false">
                <pane style="position: relative">
                    <div style="width: 100%">
                        <slot name="grid-layout"></slot>
                    </div>
                </pane>
                <pane size="30">
                    <slot name="editor"></slot>
                </pane>
            </splitpanes>
        </pane>
    </splitpanes>
</template>

<script>
import { Pane, Splitpanes } from "splitpanes"
import "splitpanes/dist/splitpanes.css"

export default {
    name: "DashboardBuilder",
    components: { splitpanes: Splitpanes, pane: Pane }
}
</script>

<style>
.splitpanes__pane {
    position: relative;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2) inset;
}
.splitpanes.default-theme .splitpanes__pane {
    background: white;
}
</style>
