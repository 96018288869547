<template>
    <div>
        <div>{{ item.label || item.name }}</div>
        <select v-model="local" class="form-control form-control-sm">
            <option :disabled="opt.disabled" :value="opt.value" v-for="opt of itemOptions" :key="opt.label">
                {{ opt.label }}
            </option>
        </select>
    </div>
</template>

<script>
import FormBuilderMixin from "@Platon/mixins/FormBuilderMixin"

export default {
    name: "BuilderSelect",
    mixins: [FormBuilderMixin],
    props: {
        item: {}
    },

    computed: {
        itemOptions() {
            return this.item.options || []
        }
    }
}
</script>
